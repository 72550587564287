html,
body {
  font-family: "Syne", sans-serif;

  // @apply text-text bg-offwhite;
  scroll-behavior: smooth;
}

// main {
//   transform: translateZ(0);
// }

.wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: calc(1180px + 40px);
  padding: 0 20px;
  @media (min-width: 800px) {
    padding: 0 60px;
    max-width: calc(1180px + 120px);
  }
}

// @mixin letter-spacing(spacing) {
//   letter-spacing: calc(-1em * 0.001 * #{$spacing});
// }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  font-size: responsive 35px 42px;
  // @include letter-spacing(-0.3);
  font-weight: 700;
  line-height: responsive 34px 47px;
  font-family: "Roobert", sans-serif;
}

h2,
.h2 {
  font-size: responsive 19px 20px;
  // @include letter-spacing(-0.3);
  font-weight: 300;
  font-family: "Roobert", sans-serif;
  line-height: responsive 20px 24px;
}

h3,
.h3 {
  font-size: responsive 16px 20px;
  font-family: "Syne", sans-serif;
  line-height: responsive 18px 24px;
  // @include letter-spacing(-0.3);
}

h4,
.h4 {
  font-size: 15px;
  // @include letter-spacing(-0.3);
}

h5,
.h5 {
  font-size: 13px;
  // @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  // @include letter-spacing(-0.3);
}

p,
.p {
  font-size: 15px;
  font-family: "Syne", sans-serif;
  line-height: 1.64;
  // @include letter-spacing(-0.3);
  margin-bottom: 1em;
  &:last-of-type {
    margin-bottom: 0;
  }
}

a,
.a {
  font-family: "Syne", sans-serif;
}

input {
  font-family: "Syne", sans-serif;
}

span {
  font-family: "Syne", sans-serif;
}

.text-xs {
  font-size: 13px;
  // @include letter-spacing(-0.3);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.wysiwyg {
  p {
    line-height: 2em;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

a:not([class]) {
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}

.main-footer a {
  white-space: nowrap;
}

input {
  border: 0 !important;
}
input:focus {
  border: 0 !important;
}

textarea:focus,
input:focus {
  outline: none !important;
  background: none;
}

*:focus {
  outline: none !important;
}
